export const APIError = {
    NONE: "NONE",
    CLIENT: "CLIENT",
    SERVER: "SERVER",
    NETWORK: "NETWORK",
    TIMEOUT: "TIMEOUT",
    UNKNOWN: "UNKNOWN"
};

export const APIErrorMessage = {
    "CLIENT" : "Uh-oh! We don’t recognize something about your action or input. Please try again.",
    "SERVER" : "Uh-oh! Something has gone wrong on our end. We’ve been notified about the issue and are working to resolve it. Please try again later.",
    "NETWORK" : "You are offline or experiencing an unstable internet connection. Please try again when you have a good connection.",
    "TIMEOUT" : "Uh-oh! Your action took too long to process. This might be because of a slow internet connection. Please try again.",
    "UNKNOWN" : "Uh-oh! Something has gone wrong on our end. We’ve been notified about the issue and are working to resolve it. Please try again later.",
};

export class APIClientResult {

    constructor(result) {
        this.result = result;
    }

    get ok() {
        return this._isOk(this.status);
    }

    get status() {
        return this._getResponseValue("status");
    }

    get data() {
        return this._getResponseValue("data");
    }

    get headers() {
        return this._getResponseValue("headers");
    }

    get problem() {
        return this._isError ? this._problemFromError(this.result) : this._problemFromStatus(this.status);
    }

    get originalError() {
        return this._isError ? this.result : null;
    }

    get errorMessage() {
        if(APIErrorMessage[this.problem]) {
            return APIErrorMessage[this.problem];
        }
        return APIErrorMessage.UNKNOWN;
    }

    get isIdentityRetrieval() {
        const url = new URL(this.result.config.url);
        return url.pathname === "/me";
    }

    get _response() {
        return this._isError ? this.result.response : this.result;
    }

    get _isError() {
        return this.result instanceof Error;
    }

    get normalizedResult() {
        return {
            ok: this.ok,
            data: this.data,
            status: this.status,
            headers: this.headers,
            problem: this.problem,
            originalError: this.originalError,
        };
    }

    _getResponseValue(key) {
        return (this._response && this._response[key]) || null;
    }

    _isOk(status) {
        return status >= 200 && status <= 299;
    }

    _problemFromStatus(status) {
        if (this._isOk(status)) {
            return APIError.NONE;
        }
        if (status >= 400 && status <= 499) {
            return APIError.CLIENT;
        }
        if (status >= 500 && status <= 599) {
            return APIError.SERVER;
        }
        return APIError.UNKNOWN;
    };

    _problemFromCode(code) {
        if (code.includes("ECONNABORTED")) {
            return APIError.TIMEOUT;
        }

        return APIError.UNKNOWN;
    };

    _problemFromError(error) {
        if (error.message === "Network Error") {
            return APIError.NETWORK;
        }

        if (error.code) {
            return this._problemFromCode(error.code);
        } else {
            const status = error.response ? error.response.status : null;
            return this._problemFromStatus(status);
        }
    };
}
