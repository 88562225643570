export default function make(analyticFn) {
    return {
        inserted(el, binding, vnode, oldVnode) {
            errorOnFalsy(binding.value);
            
            let events = Object.keys(binding.modifiers);
            if (events.length === 0) {
                events.push('click');
            }
            
            registerEventListeners(analyticFn, el, events, binding.value);
        }
    };
}

function errorOnFalsy(value) {
    if (!value) {
        throw new Error('Analytics directive requires a value');
    }
}

function registerEventListeners(analyticFn, el, events, value) {
    events.forEach(event => {
        el.addEventListener(event, () => {
            if (Array.isArray(value)) {
                analyticFn(...value);
            } else {
                analyticFn(value)
            }
        });
    });
}