import TutoringApiClient from "./tutoring-api-client";

const defaultConfig = {
    baseURL: process.env.TUTORING_API_URL + "/graphql/public",
};

export default class GraphqlAPIClient extends TutoringApiClient {

    constructor(config={}, connection = null){
        super(GraphqlAPIClient._setConfigDefaults(config), connection);
    }

    static _setConfigDefaults(config){
        return {
            ...defaultConfig,
            ...config
        }
    }

    query(query, variables={}) {
        return this._requestWithBody("post", this.config.baseURL, {
            query: query,
            variables: variables
        });
    }
}
