function isObject(obj) {
    const type = typeof obj;
    return type === "function" || type === "object" && !!obj;
}

export function pick(obj, keys) {
    return keys.map(k => k in obj ? {[k]: obj[k]} : {})
        .reduce((res, o) => Object.assign(res, o), {});
}


export function reject(obj, keys) {
    return Object.keys(obj)
        .filter(k => !keys.includes(k))
        .map(k => Object.assign({}, {[k]: obj[k]}))
        .reduce((res, o) => Object.assign(res, o), {});
}


export function flatten(obj) {
    return Object.assign(
        {},
        ...function _flatten(o) {
            return [].concat(...Object.keys(o)
                .map(k =>
                    typeof o[k] === "object" ?
                        _flatten(o[k]) :
                        ({[k]: o[k]})
                )
            );
        }(obj)
    );
}

export function renameKeys(obj, renameFn) {

    if (Array.isArray(obj)) {
        return obj.map((i) => {
            return renameKeys(i, renameFn);
        });
    } else if (isObject(obj)) {
        const newObj = {};

        Object.keys(obj)
            .forEach((k) => {
                newObj[renameFn(k)] = renameKeys(obj[k], renameFn);
            });

        return newObj;

    }

    return obj;
}

export default {
    pick,
    reject,
    flatten,
    renameKeys,
};
