import {component} from "./lib";

component(".gooroo-courses-tabs", (el) => {
    // ------------------------------------------------------------------------
    //  Elements
    // ------------------------------------------------------------------------

    const links = el.querySelectorAll(".gooroo-courses-tabs-nav a");
    const tabs = el.querySelectorAll(".gooroo-courses-tabs-content > div");

    // ------------------------------------------------------------------------
    //  State
    // ------------------------------------------------------------------------

    let currentTab = 0;

    // ------------------------------------------------------------------------
    //  Methods
    // ------------------------------------------------------------------------

    const updateUI = () => {
        for (let i=0; i<links.length; i+=1) {
            links[i].classList.toggle("is-active", i === currentTab);
            tabs[i].toggleAttribute("hidden", i !== currentTab);
        }
    };

    // ------------------------------------------------------------------------
    //  Event binding
    // ------------------------------------------------------------------------

    for (let i=0; i<links.length; i+=1) {
        links[i].addEventListener("click", (e) => {
            console.log(e);
            e.preventDefault();
            currentTab = i;
            updateUI();
        });
    }

    // ------------------------------------------------------------------------
    //  Bootstrapping
    // ------------------------------------------------------------------------

    // Initial update
    updateUI();
});
