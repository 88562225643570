import Cookie from "../../lib/helpers/cookie-helper";

export default function (request) {
    const token = Cookie.get(process.env.GOOROO_SSO_COOKIE || "goo_sid");

    if (token !== null) {
        request.headers["Authorization"] = `Bearer ${token}`;
    }

    return request
}
