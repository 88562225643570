import Vue from "vue";

const Logout = () => import("./account/logout/app" /* webpackChunkName: "logout" */ );
const GoogleMap = () => import("../components/google-map/google-map" /* webpackChunkName: "google-map" */ );
const ResetPassword = () => import("./account/reset-password/app" /* webpackChunkName: "reset-password" */ );
const SeoSubjectSearch = () => import("./seo-subject-search/app" /* webpackChunkName: "seo-subject-search" */ );
const LoginOrSignUp = () => import("./account/login-or-sign-up/app" /* webpackChunkName: "login-or-sign-up" */ );
const CheckAuth = () => import("./account/login-or-sign-up/check-auth" /* webpackChunkName: "check-auth" */)
const ExploreSubjects = () => import("../components/explore-subjects" /* webpackChunkName: "explore-subjects" */ );
const TutorScreeningFlow = () => import("./teach/tutor-screening/app" /* webpackChunkName: "tutor-screening-flow" */ );
const MembershipEnrollment = () => import("./membership-enrollment/app" /* webpackChunkName: "membership-enrollment" */ );
const MembershipConsultation = () => import("./membership-consultation/app" /* webpackChunkName: "membership-consultation" */ );
const RequestConfirmation = () => import("./learn/request-confirmation/app" /* webpackChunkName: "request-confirmation" */);
const TutorApplicationFlow = () => import("./teach/tutor-application/app" /* webpackChunkName: "tutor-application-flow" */ );
const MembershipSlider = () => import("../components/membership/membership-slider" /* webpackChunkName: "membership-slider" */ );
const TutorApplicationStatus = () => import("./teach/tutor-application-status/app" /* webpackChunkName: "tutor-application-status" */ );
const GoorooLiveEnroll = () => import("./learn/gooroo-live-enroll/app" /* webpackChunkName: "gooroo-live-enroll" */);

const CourseSearchField = () => import("../components/landing-page/course-search-field" /* webpackChunkName: "landing-page" */);

if (document.querySelector("#goo-app")) {
    const app = new Vue({
        el: "#goo-app",
        delimiters: ['${', '}'],
        components: {
            Logout,
            GoogleMap,
            LoginOrSignUp,
            CheckAuth,
            ResetPassword,
            ExploreSubjects,
            GoorooLiveEnroll,
            SeoSubjectSearch,
            MembershipSlider,
            TutorScreeningFlow,
            RequestConfirmation,
            MembershipEnrollment,
            MembershipConsultation,
            TutorApplicationFlow,
            TutorApplicationStatus,
            CourseSearchField,
        },
    });
}

if (document.querySelector("#request-a-tutor-app")) {
    import("@/apps/learn/request-tutor" /* webpackChunkName: "request-a-tutor-app" */).then(({createRequestATutorApp}) => {
        const app = createRequestATutorApp();
        app.$mount("#request-a-tutor-app");
    });
}

if (document.querySelector("#instant-match-app")) {
    import("@/apps/learn/instant-match" /* webpackChunkName: "instant-match-app" */).then(({createInstantMatchApp}) => {
        const app = createInstantMatchApp();
        app.$mount("#instant-match-app");
    });
}

if (document.querySelector("#student-app")) {
    import("@/apps/learn/student-app" /* webpackChunkName: "student-app" */).then(({createStudentApp}) => {
        const app = createStudentApp();
        app.$mount("#student-app");
    });
}

if (document.querySelector("#tutor-app")) {
    import("@/apps/teach/tutor-app" /* webpackChunkName: "tutor-app" */).then(({createTutorApp}) => {
        const app = createTutorApp();
        app.$mount("#tutor-app");
    });
}

if (document.querySelector("#virtual-classroom-app")) {
    import("@/apps/virtual-classroom" /* webpackChunkName: "virtual-classroom-app" */).then(({createVirtualClassroomApp}) => {
        const app = createVirtualClassroomApp();
        app.$mount("#virtual-classroom-app");
    });
}


