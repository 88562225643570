export function whenReady(fn) {
    if (document.readyState !== "loading") {
        fn();
    } else {
        document.addEventListener("DOMContentLoaded", fn);
    }
}

export function component(selector, fn) {
    whenReady(function () {
        const elements = document.querySelectorAll(selector);
        elements.forEach(fn);
    });
}
