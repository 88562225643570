import User from "@/lib/session/user";
import Session from "@/lib/session/session";

/**
 * @param response {APIClientResult}
 */
export default function (response) {
    if (!response.ok && response.status === 401 && !response.isIdentityRetrieval) {
        Session.destroy();
        User.clear();
        window.location = "/login?next=" + encodeURIComponent(window.location.pathname);
    }
}
