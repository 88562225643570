import {component} from "./lib";

component('.tutor-quotes', (el) => {
    let currentSlide = 0;

    // ------------------------------------------------------------------------
    //  Elements
    // ------------------------------------------------------------------------

    const slides = el.querySelectorAll('.tutor-quotes-quote');
    const images = el.querySelectorAll('.tutor-quotes-image.is-large');
    const smallImages = el.querySelectorAll('.tutor-quotes-image.is-small');
    const thumbnails = el.querySelectorAll('.tutor-quotes-thumbnail');

    // Next and Prev buttons
    const next = el.querySelector(".carousel-button.is-next");
    const prev = el.querySelector(".carousel-button.is-prev");

    // ------------------------------------------------------------------------
    //  Methods
    // ------------------------------------------------------------------------

    const updateUI = () => {
        for (let i=0; i<slides.length; i+=1) {
            slides[i].classList.toggle('is-active', i === currentSlide);
            images[i].classList.toggle('is-active', i === currentSlide);
            smallImages[i].classList.toggle('is-active', i === currentSlide);
            thumbnails[i].classList.toggle('is-active', i === currentSlide);
        }
    };

    const navigate = (delta) => {
        currentSlide += delta;

        if (currentSlide >= slides.length) {
            currentSlide = 0;
        } else if (currentSlide < 0) {
            currentSlide = slides.length - 1;
        }

        updateUI();
    }

    // ------------------------------------------------------------------------
    //  Event binding
    // ------------------------------------------------------------------------

    for (let i=0; i<thumbnails.length; i+=1) {
        thumbnails[i].addEventListener('click', function (e) {
            e.preventDefault();
            currentSlide = i;
            updateUI();
        });
    }

    // Next prev
    next.addEventListener("click", (e) => {
        e.preventDefault();
        navigate(1);
    });

    prev.addEventListener("click", (e) => {
        e.preventDefault();
        navigate(-1);
    });

    // ------------------------------------------------------------------------
    //  Bootstrapping
    // ------------------------------------------------------------------------

    // Initial update
    updateUI();
});
