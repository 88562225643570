import APIClient from "./api-client";

const XAPIVersion = "2019.12.19";

const defaultHeaders = {
    "X-API-KEY": process.env.TUTORING_API_KEY,
    "X-API-Version": XAPIVersion
};

export default class TutoringAPIClient extends APIClient {
    constructor(config, connection = null) {
        config.headers = {
            ...defaultHeaders,
            ...(config.headers ? config.headers : {})
        };

        super(config, connection);
    }
}
