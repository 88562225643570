/**
 * Set cookie
 * @param name {string} cookie name
 * @param value {string} token
 * @param options {{expiration: number, sameSite?: string, domain?: string}} cookie options.
 */
export function set(name, value, options) {
    const attributes = [];
    attributes.push(name + '=' + value);

    let date = new Date(options.expiration * 1000);
    attributes.push('Expires=' + date.toUTCString());

    if (options.sameSite) {
        attributes.push('SameSite=' + options.sameSite);
    }

    if (options.domain) {
        attributes.push('Domain=' + options.domain)
    }

    attributes.push('Path=/');
    document.cookie = attributes.join('; ');
}

/**
 * Get cookie
 * @param name {string}
 * @return {string|null}
 */
export function get(name) {
    let nameEquals = name + '=';
    let cookiesArray = document.cookie.split(';');

    for (let i = 0; i < cookiesArray.length; i++) {
        let cookie = cookiesArray[i];

        while (cookie.charAt(0) === ' ') {
            cookie = cookie.substring(1, cookie.length);
        }

        if (cookie.indexOf(nameEquals) === 0) {
            return cookie.substring(nameEquals.length, cookie.length);
        }
    }

    return null;
}

/**
 * Remove cookie
 * @param name {string}
 */
export function remove(name) {
    return set(name, '', {
        expiration: -1
    });
}

export default {
    set: set,
    get: get,
    remove: remove
};
