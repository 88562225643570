import ObjectHelper from "@/lib/helpers/object-helper";
import Session from "@/lib/session/session";
import {TutoringAPIClient} from "./client";

const config = {
    baseURL: process.env.TUTORING_API_URL,
};

export default {

    client: new TutoringAPIClient(config),

    captureToken(response) {
        if (response.data && response.data.token) {
            Session.setToken(response.data.token);
        }
        return response;
    },

    getAcquisitionChannels() {
        return this.client.get("/acquisition-channels");
    },

    addAcquisitionChannelTag(requestParams) {
        const tag = Session.getAcquisitionChannelTag();
        if (!tag) {
            return requestParams;
        }

        return {
            ...requestParams,
            acquisition_channel_tag: tag,
        };
    },

    subscribeToMailingList(email, tag) {
        return this.client.post("/mailing-list/subscribe", {
            email: email,
            tag: tag,
        });
    },

    createUser(userData) {
        return this.client.post("/users", this.addAcquisitionChannelTag(userData)).then(this.captureToken);
    },

    loginUser(credentials) {
        return this.client.post("/auth/login", credentials).then(this.captureToken);
    },

    loginWithGoogle(token) {
        const loginData = {
            google_id_token: token,
        };
        return this.client.post("/auth/login/google", this.addAcquisitionChannelTag(loginData)).then(this.captureToken);
    },

    getCurrentUser() {
        return this.client.get("/me");
    },

    logout() {
        return this.client.post("/auth/logout");
    },

    sendForgotPasswordLink(email) {
        return this.client.post("/auth/forgot-password", {
            email: email,
        });
    },

    resetPassword(password, token) {
        return this.client.post("/auth/reset-password", {
            token: token,
            password: password,
        });
    },

    addStudent(studentProfile, learningStyles) {
        return this.addStudentProfile(studentProfile).then(({data}) => {
            return this.updateLearningStyles(data.id, learningStyles);
        });
    },

    addStudentProfile(studentProfile) {
        return this.client.post("/me/student-profiles", this._profileToAPI(studentProfile));
    },

    updateStudentProfile(studentProfileId, studentProfile) {
        return this.client.put("/me/student-profiles/" + studentProfileId, this._profileToAPI(studentProfile));
    },

    _profileToAPI(profile) {
        return ObjectHelper.reject(profile, ["is_student"]);
    },

    getStudentLearningSessions(studentProfileId) {
        // START API BAND-AID
        return this.client.get("/me/learning-sessions").then(({data}) => {
            let studentSessions = data.items.filter(session => {
                return session.student_profile.profile_id == studentProfileId;
            });
            const latestSession = studentSessions.length ? studentSessions[0] : null;
            return Promise.resolve({
                data: {
                    latest_session: latestSession,
                    total_sessions: studentSessions.length,
                },
            });
        });
        // END API BAND-AID

        return this.client.get("/me/student-profiles/" + studentProfileId + "/learning-sessions");
    },

    getStudentAssessment(studentProfileId) {
        return this.client.get("/me/student-profiles/" + studentProfileId + "/assessment");
    },

    getStudentProfiles() {
        return this.client.get("/me/student-profiles");
    },

    updateLearningStyles(studentProfileId, learningStyles) {
        return this.client.put("/me/student-profiles/" + studentProfileId + "/learning-styles", learningStyles);
    },
};
