import axios from "axios";
import {APIClientResult} from "./api-client-result";

const pipe = (...fns) => x => fns.reduce((v, f) => f(v), x);

export default class APIClient {

    constructor(config, connection = null) {

        this.config = config;

        this.connection = typeof connection === 'function' ? connection(this.config) : axios.create(this.config);
        this.requestMiddleware = [];
        this.responseMiddleWare = [];
    }

    addRequestMiddleware(middleware) {
        this.requestMiddleware.push(middleware);
    }

    addResponseMiddleware(middleware) {
        this.responseMiddleWare.push(middleware);
    }

    _handleResult(result) {
        return new APIClientResult(result);
    }

    _requestWithoutBody(method, url, config = {}) {
        const requestConfig = Object.assign({method, url}, config);
        return this.request(requestConfig);
    }

    _requestWithBody(method, url, data = null, config = {}) {
        const requestConfig = Object.assign({method, url, data}, config);
        return this.request(requestConfig);
    }

    _runRequestMiddleware(config) {
        for (let i = 0; i < this.requestMiddleware.length; i+=1) {
            const middleware = this.requestMiddleware[i];
            config = middleware(config)
        }

        return config
    }

    _runResponseMiddleware(response) {
        this.responseMiddleWare.forEach(middleware => {
            middleware(response);
        });
        return response;
    }

    _mergedHeaders(config) {
        return Object.assign({}, this.config.headers || {}, config.headers || {}, {});
    }

    _runRequest(config) {
        config.headers = this._mergedHeaders(config);
        config = this._runRequestMiddleware(config);

        const chain = pipe(this._handleResult.bind(this), this._runResponseMiddleware.bind(this));

        return this.connection.request(config)
            .then(chain)
            .catch(chain);
    }

    request(config) {
        return new Promise((resolve, reject) => {
            this._runRequest(config).then(response => {
                if(!response.ok) {
                    reject(response);
                } else {
                    resolve(response);
                }
            });
        });
    }

    get(url, config = {}) {
        return this._requestWithoutBody("get", url, config);
    }

    delete(url, config = {}) {
        return this._requestWithoutBody("delete", url, config);
    }

    post(url, data, config = {}) {
        return this._requestWithBody("post", url, data, config);
    }

    put(url, data, config = {}) {
        return this._requestWithBody("put", url, data, config);
    }
}
