import Cookie from "../helpers/cookie-helper";

class Session {
    get _ssoCookieName() {
        return process.env.GOOROO_SSO_COOKIE || "goo_sid";
    }

    get _ssoCookieDomain() {
        return process.env.GOOROO_SSO_COOKIE_DOMAIN || ".gooroo.com";
    }

    setToken(token) {
        let tokenExpiration = this._getTokenExpiration(token);
        Cookie.set(this._ssoCookieName, token, {
            expiration: tokenExpiration,
            sameSite: "Lax",
            domain: this._ssoCookieDomain
        });
    }

    getToken() {
        return Cookie.get(this._ssoCookieName);
    }

    getAcquisitionChannelTag() {
        const tag = Cookie.get("goo:acq_channel_tag");
        if(!tag) {
            return null;
        }

        return decodeURIComponent(tag);
    }

    get(key) {
        return Cookie.get(key);
    }

    set(key, value, options) {
        return Cookie.set(key, value, options);
    }

    remove(key) {
        return Cookie.remove(key);
    }

    destroy() {
        return Cookie.remove(this._ssoCookieName);
    }

    isLoggedIn() {
        return this.getToken() !== null;
    }

    _getTokenExpiration(token) {
        let payloadString = token.split('.')[1];
        let payload = JSON.parse(atob(payloadString));

        if (!payload.exp) {
            throw Error('Token missing expiration');
        }

        return payload.exp;
    }
}

export default new Session();
