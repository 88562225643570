import {APIClient as BaseClient} from "../../../../src/api-client";
import {clearError, unauthenticated, injectAuthHeader} from "../middleware";

const XAPIVersion = "2019.12.19";

const defaultHeaders = {
    "X-API-KEY": process.env.TUTORING_API_KEY,
    "X-API-Version": XAPIVersion
};

export default class APIClient extends BaseClient {
    constructor(config, connection = null) {
        config.headers = {
            ...defaultHeaders,
            ...(config.headers ? config.headers : {})
        };

        super(config, connection);

        this.addRequestMiddleware(injectAuthHeader);
        this.addRequestMiddleware(clearError);
        this.addResponseMiddleware(unauthenticated);
    }
}
