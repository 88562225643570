import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";
import {auto} from "browser-unhandled-rejection";
import Vue from "vue";
import {APIClientResult} from "../../../../src/api-client/api-client-result";
import {EventBus} from "../event-bus/event-bus";

auto(); // Applies polyfill if necessary to window.Promise

if (process.env.SENTRY_ENV) {
    Sentry.init({
        dsn: process.env.SENTRY_DSN,
        environment: process.env.SENTRY_ENV,
        whitelistUrls: [
            window.location.host,
             ...(process.env.CDN_HOST ? [process.env.CDN_HOST] : [])
        ],
        integrations: [new Integrations.Vue({Vue, attachProps: true, logErrors: true})],
        ...process.env.SENTRY_RELEASE && {release: process.env.SENTRY_RELEASE},
    });
}


window.addEventListener("unhandledrejection", (event) => {
    event.preventDefault();

    console.error(event.reason);

    if (event.reason instanceof APIClientResult) {
        EventBus.$emit("goo-toasts.add", {
            id: "request-error",
            message: event.reason.errorMessage,
            type: "error",
            dismissable: true,
        });
    }
});
