import {component} from "./lib";

function carouselComponent(element) {
    component(  `.${element}-carousel`, (el) => {
        // ------------------------------------------------------------------------
        //  Elements
        // ------------------------------------------------------------------------

        const track = el.querySelector(`.${element}-carousel-track`);

        // Next and Prev buttons
        const next = el.querySelector(".carousel-button.is-next");
        const prev = el.querySelector(".carousel-button.is-prev");

        // ------------------------------------------------------------------------
        //  Methods
        // ------------------------------------------------------------------------

        /**
         * Update the carousel UI, hiding next and prev buttons if needed.
         */
        const updateUI = () => {
            const hidePrev = track.scrollLeft === 0;
            const hideNext = (track.scrollLeft + track.offsetWidth) === track.scrollWidth;
            prev.classList.toggle("is-hidden", hidePrev);
            next.classList.toggle("is-hidden", hideNext);
        };

        /**
         * Scroll in a given direction.
         * @param direction {number} direction of the scroll. 1 for right, -1 for left.
         */
        const scroll = (direction) => {
            const currentSnappedPosition = Math.round(track.scrollLeft / track.offsetWidth) * track.offsetWidth;
            track.scroll(currentSnappedPosition + (track.offsetWidth * direction), 0);
        };

        // ------------------------------------------------------------------------
        //  Event binding
        // ------------------------------------------------------------------------

        track.addEventListener("scroll", () => updateUI());

        // Next prev
        next.addEventListener("click", (e) => {
            e.preventDefault();
            scroll(1);
        });

        prev.addEventListener("click", (e) => {
            e.preventDefault();
            scroll(-1);
        });

        // ------------------------------------------------------------------------
        //  Bootstrapping
        // ------------------------------------------------------------------------

        // Initial update
        updateUI();
    });
};

carouselComponent("course")
carouselComponent("club")


