export default {

    event(...args) {
        if (typeof window.ga === "function") {
            if (args.length === 1) {
                if (typeof args[0] === "string" || args[0] instanceof String) {
                    args.push("click");
                } else if (typeof args[0] === "object") {
                    const obj = args[0];
                    args = [obj.category, obj.action || "click", obj.label];
                }
            }

            ga("send", "event", ...args);
        }
    }
};
