import AccountAPI from "@/api/accounts-api";
import Vue from "vue";
import Session from "./session";

export default class User {

    static get() {
        if (!Session.isLoggedIn()) {
            User.clear();
            return null;
        }

        let currentUser = localStorage.getItem("goo:user");
        if (!currentUser) {
            User.refresh();
        }
    
        return JSON.parse(currentUser);
    }
    
    static setProp(prop, val){
        const user= this.get();
        user[prop] = val;
        this.set(user);
    }

    static set(user) {
        localStorage.setItem("goo:user", JSON.stringify(user));
        Vue.prototype.$user = this.get();
    }

    static refresh() {
        return AccountAPI.getCurrentUser().then(({data}) => {
            User.set(data);
        });
    }

    static clear() {
        localStorage.removeItem("goo:user");
    }
}

Vue.prototype.$user = User.get();

