export class GooFeatures {
    constructor() {
        this._flags = {};
    }

    setFlags(flags) {
        this._flags = flags;
    }

    enabled(flagName) {
        if (typeof this._flags[flagName] === "undefined") {
            return false;
        }

        return this._flags[flagName].enabled;
    }

    configValue(configName) {
        if (typeof this._flags[configName] === "undefined") {
            return false;
        }

        return this._flags[configName].value;
    }
}

const features = new GooFeatures();

//This is kind of a hack to make sure that the flags are set and available whenever you get the shared instance
features.setFlags(JSON.parse(window.GOO_FEATURES || "{}"));

export  default features;
